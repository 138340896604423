<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import BeginnerModalModule from "./BeginnerModalModule.vue";
import EventHub from '../../libs/EventHub';
import CommonUtil from '../../libs/CommonUtil';
import {
    TRAFFIC_TYPE_SELL,
    OFFER_FLAG_NO,
    OFFER_FLAG_YES,
    LOGIN_SUCCESS_EVENT
} from '../../libs/Mutations';
import Mixin from '../../libs/Mixin';

interface IState {
    isActive: boolean,
    tooltipActive: boolean,
    isBidDescribe: boolean,
    bidPrice: string,
    errorBidPrice: string,
    isWatching: number,
    watchState: boolean,
    archiveDisplay: string,
    itemTypeSell: boolean,
    trafficType: number,
    checkedNegotiate: boolean,
    offerPrice: number|string,
    DA: number,
    maxDA: number,
    isShowHoldModal: boolean,
    isClosedBeginnerModal: boolean,
    tenThousand: number,
    oneHundredThousand: number,
    oneMillion: number,
    isPressedEasyBidPrice: number,
    isShowedBeginnerModal: boolean,
    tooltipRecommendActive: boolean,
}

const RELOAD_INTERVAL_TIME: number = 10;

const MIN_START_PRICE: number = 1000;

const BID_MIN_PRICE: number = 10200;

const ERROR_MESSAGE_SAME_ACCOUNT_FLG: string = 'ドメイン管理中のIDでは、お申し込みいただけません。';

let timer: any = null;
let reload: boolean = false;
let timeoutAddAutoBidPrice: any = null;

export default defineComponent({
    mixins: [Mixin],
    components: {
        BeginnerModalModule
    },
    props: {
        isEnd: {
            type: Boolean,
            required: true
        },
        losing: {
            type: Boolean,
            required: true
        },
        justLosing: {
            type: Boolean,
            required: true
        },
        showNoticeLosing: {
            type: Boolean,
            required: true
        },
        showNoticeHold: {
            type: Boolean,
            required: true
        },
        isAddCurrentPrice: {
            type: Boolean,
            required: false
        },
        isEntered: {
            type: Boolean,
            required: true
        }
    },
    setup() {
        const state = reactive<IState>({
            isActive: false,
            tooltipActive: false,
            isBidDescribe: false,
            bidPrice: '',
            errorBidPrice: '',
            isWatching: 0,
            watchState: false,
            archiveDisplay: '',
            itemTypeSell: false,
            trafficType: 0,
            checkedNegotiate: false,
            offerPrice: 0,
            DA: 0,
            maxDA: 0,
            isShowHoldModal: true,
            isClosedBeginnerModal: false,
            tenThousand: 10000,
            oneHundredThousand: 100000,
            oneMillion: 1000000,
            isPressedEasyBidPrice: 0,
            isShowedBeginnerModal: false,
            tooltipRecommendActive: false,
        });

        return {
            ...toRefs(state)
        };
    },
    created() {
        if (this.router.market === 'jp' || this.router.market === 'other') {
            CommonUtil.events('event606');
            if (CommonUtil.getCookie('contents17_auction') === 'contents17_complete') {
                CommonUtil.events('event619');
            }
        }
        this.trafficType = this.item.trafficType;
        this.isWatching = this.item.isWatching;
        this.archiveDisplay = this.item.archiveDisplay;

        this.isShowedBeginnerModal = CommonUtil.getCookie('show_beginner_modal') === '1';

        if (this.item.trafficType === TRAFFIC_TYPE_SELL) {
            // ドメイン売買
            this.offerPrice = this.numberFormat(this.item.startPrice);
            this.itemTypeSell = true;
            if (this.item.offerFlg === 1) {
                this.checkedNegotiate = true;
            }

            EventHub.on('afterRender', () => {
                this.$nextTick(() => {
                    this.isWatching = this.item.isWatching;
                    this.$emit('resetNoticeShow');
                    if (this.item.offerFlg === 1 && this.checkedNegotiate) {
                        this.offerPrice = this.numberFormat(this.item.startPrice);
                    }
                });
            });

        } else {
            // オークション
            if (this.isEnd) {
                this.bidPrice = '';
            } else {
                this.bidPrice = this.numberFormat(this.item.bidPricePlus);
            }

            let isAuctionEndCountDown: boolean = false;

            const auctionCountDown = () => {
                if (timer !== null) {
                    clearInterval(timer);
                }
                if (isAuctionEndCountDown) {
                    return;
                }
                const timeleft = this.item.timeleft;

                const matches: any = (/^(\d+)D(\d+)H(\d+)M(\d+)S$/.exec(timeleft));
                if (matches !== null) {
                    const days: number = parseInt(matches[1], 10);
                    const hours: number = parseInt(matches[2], 10);
                    const minutes: number = parseInt(matches[3], 10);
                    const seconds: number = parseInt(matches[4], 10);
                    // 残り時間を秒に変換
                    let diff = seconds;
                    if (days > 0) {
                        diff += days * 24 * 60 * 60;
                    }
                    if (hours > 0) {
                        diff += hours * 60 * 60;
                    }
                    if (minutes > 0) {
                        diff += minutes * 60;
                    }
                    if (diff >= 0) {
                        // カウントダウンを始める
                        timer = setInterval(() => {
                            // 10秒減らせる
                            diff -= RELOAD_INTERVAL_TIME;
                            // ajax処理中はなにもしない
                            if (diff < 0) {
                                clearInterval(timer);
                                isAuctionEndCountDown = true;
                                this.$store.dispatch('render', this.router);
                            } else {
                                if (!reload && !this.$store.state.Common.ajaxProgress) {
                                    reload = true;
                                    // 10分以内の場合はデータを更新する
                                    if (diff <= 60 * 10) {
                                        this.$store.dispatch('reloadItemInfo', {
                                            itemId: this.router.itemId
                                        }).then((response: any) => {
                                            if (response.data.success) {
                                                reload = false;
                                                this.$nextTick(() => {
                                                    this.isWatching = this.item.isWatching;
                                                    this.isShowHoldModal = true;
                                                    this.$emit('resetNoticeShow');
                                                    auctionCountDown();
                                                });
                                            }
                                        });
                                    }
                                }
                            }
                        }, RELOAD_INTERVAL_TIME * 1000);
                    }
                }
            };
            auctionCountDown();

            EventHub.on('afterRender', () => {

                this.$nextTick(() => {
                    if(this.isEnd) {
                        this.bidPrice = '';
                    } else {
                        this.bidPrice = this.numberFormat(this.item.bidPricePlus);
                    }

                    this.isWatching = this.item.isWatching;
                    this.isShowHoldModal = true;
                    this.$emit('resetNoticeShow');
                    auctionCountDown();
                });
            });

            EventHub.on('syncBidPrice', (price: any) => {
                this.bidPrice = this.numberFormat(price);
            });
        }
    },
    mounted() {
        if (this.router.market === 'jp' || this.router.market === 'other') {
            let targetPie: HTMLElement = <HTMLElement>document.getElementById('pie');
            // DAの最大値
            this.maxDA = Number(this.item.maxDA);
            // itemのDA
            this.DA = Number(this.item.DA);
                
            let calcPerCent = (this.DA / this.maxDA) * 100;

            if ( calcPerCent > 0 && calcPerCent <= 19 ) {
                targetPie.classList.add('is-10');
            } else if (calcPerCent >= 20 && calcPerCent <= 29) {
                targetPie.classList.add('is-20');
            } else if (calcPerCent >= 30 && calcPerCent <= 39) {
                targetPie.classList.add('is-30');
            } else if (calcPerCent >= 31 && calcPerCent <= 49) {
                targetPie.classList.add('is-40');
            } else if (calcPerCent >= 41 && calcPerCent <= 59) {
                targetPie.classList.add('is-50');
            } else if (calcPerCent >= 51 && calcPerCent <= 69) {
                targetPie.classList.add('is-60');
            } else if (calcPerCent >= 61 && calcPerCent <= 79) {
                targetPie.classList.add('is-70');
            } else if (calcPerCent >= 71 && calcPerCent <= 89) {
                targetPie.classList.add('is-80');
            } else if (calcPerCent >= 81 && calcPerCent <= 99) {
                targetPie.classList.add('is-90');
            } else if (calcPerCent === 100) {
                targetPie.classList.add('is-100');
            }
        }
    },
    methods: {
        setActive(): void {
            this.isActive = !this.isActive;
        },
        scrToggle(e: any): void {
            e.preventDefault();
        },
        tooltipOn(tooltipContent: string | undefined = undefined): void {
            if (tooltipContent === 'recommendInfo') {
                if (this.router.market === 'jp' || this.router.market === 'other') {
                    CommonUtil.events('event632');
                    this.tooltipRecommendActive = true;
                }
            } else {
                this.tooltipActive = true;
            }
        },
        tooltipLeave(tooltipContent: string | undefined = undefined): void {
            if (tooltipContent === 'recommendInfo') {
                if (this.router.market === 'jp' || this.router.market === 'other') {
                    this.tooltipRecommendActive = false;
                }
            } else {
                this.tooltipActive = false;
            }
        },
        bidModalActive(): void {
            this.isBidDescribe = !this.isBidDescribe;

            let body = document.body;
            let fixedClass = 'is-fixed';
            let scrEvent = `onwheel` in document ? `wheel` : `onmousewheel` in document ? `mousewheel` : `DOMMouseScroll`;

            if (this.isBidDescribe) {
                body.classList.add(fixedClass);
                if (body.classList.contains(fixedClass)) {
                    if(window.innerWidth > 767) { // PC
                        body.addEventListener(scrEvent, this.scrToggle, {
                            capture: false,
                            passive: false
                        });
                        body.removeEventListener(`touchstart`, this.scrToggle, false);
                    } else { // SP
                        body.removeEventListener(scrEvent, this.scrToggle, false);

                        body.addEventListener(`touchstart`, this.scrToggle, true);
                    }

                    window.onresize = () =>{
                        if(window.innerWidth > 767) { // PC
                            body.addEventListener(scrEvent, this.scrToggle, {
                                capture: false,
                                passive: false
                            });
                            body.removeEventListener(`touchstart`, this.scrToggle, false);
                        } else { // SP
                            body.removeEventListener(scrEvent, this.scrToggle, false);

                            body.addEventListener(`touchstart`, this.scrToggle, false);
                        }
                    };
                }

            } else {
                body.classList.remove(fixedClass);
                body.removeEventListener(scrEvent, this.scrToggle, false);
                body.removeEventListener(`touchstart`, this.scrToggle, false);
            }
        },
        beginnerModalClose(): void {
            let beginnerModal: HTMLElement = <HTMLElement>document.getElementById('beginnerModal');
            let beginnerModalOverlay: HTMLElement = <HTMLElement>document.getElementById('beginnerModalOverlay');

            if (beginnerModal.classList.contains('is-fixed')) {
                beginnerModal.classList.remove('is-fixed');
                beginnerModalOverlay.classList.remove('is-fixed');
            }
        },
        bidSubmit(e: Event): void {
            e.preventDefault();
            this.errorBidPrice = '';
            let bidPrice: number = 0;
            CommonUtil.events('event516');
            if (this.item.sameAccountFlg) {
                this.errorBidPrice = ERROR_MESSAGE_SAME_ACCOUNT_FLG;
            } else if(!/[^\s]/.test(this.bidPrice)) {
                this.errorBidPrice = '入札金額を入力してください。';
            } else if(!/^[1-9](?:[\d,]+)?$/.test(this.bidPrice)) {
                this.errorBidPrice = '正しい形式で入力してください。';
            } else {
                bidPrice = parseInt(this.bidPrice.replace(/,/g, ''), 10);

                if (bidPrice < this.item.bidMinPrice) {
                    this.errorBidPrice = '最低入札額(' + this.numberFormat(this.item.bidMinPrice) + '円)よりも高い金額を指定してください。';
                }
            }

            if (this.errorBidPrice.length === 0) {
                if (!this.isLogin) {
                    // 未ログイン
                    this.$store.dispatch('setWatchEventLogin', 'loginBid');
                    this.$store.dispatch('showModal', 'login');
                    // ログインクールバックを登録
                    EventHub.once(LOGIN_SUCCESS_EVENT, () => {
                        if (this.item.sameAccountFlg) {
                            this.$notify({
                                type: 'error',
                                group: 'notifications',
                                text: ERROR_MESSAGE_SAME_ACCOUNT_FLG
                            });
                        } else {
                            this.$store.dispatch('setBidPrice', bidPrice);
                            if (this.$store.state.Common.cardCheckFlg === '1') {
                                // クレジットカードありの場合
                                this.$store.dispatch('showModal', 'bid');
                            } else {
                                // クレジットカードなし
                                this.$store.dispatch('showModal', 'creditCard');
                            }
                        }
                    });
                } else {
                    // ログイン済み
                    this.$store.dispatch('setBidPrice', bidPrice);
                    if (this.$store.state.Common.cardCheckFlg === '1') {
                        // クレジットカードありの場合
                        this.$store.dispatch('showModal', 'bid');
                    } else {
                        // クレジットカードなし
                        this.$store.dispatch('showModal', 'creditCard');
                    }
                }
            }
        },
        setWatching(type: string = 'default', setEvent: string = 'loginDefault'): void {
            if (!this.isLogin) {
                if(this.router.market === 'jp') {
                    this.$store.dispatch('setWatchEventLogin', setEvent);
                    if(setEvent === 'loginWatching') {
                        CommonUtil.events('event551');
                    }
                }
                this.$store.dispatch('showModal', 'login');
                EventHub.once(LOGIN_SUCCESS_EVENT, () => {
                    this.$store.dispatch('addWatchList', this.router.itemId).then((response: any) => {
                        if (response.data.success) {
                            if (this.router.market === 'sell') {
                                CommonUtil.events('event309');
                            } else if (this.router.market === 'other') {
                                CommonUtil.events('event537');
                            } else {
                                CommonUtil.events('event54');
                            }
                        }
                    });
                    this.$store.dispatch('render', this.router);
                });
            } else {
                if (this.isWatching) {
                    this.$store.dispatch('deleteWatchList', this.router.itemId).then(response => {
                        if (response.data.success) {
                            this.isWatching = 0;
                        }
                    });
                } else {
                    if(this.router.market === 'jp') {
                        CommonUtil.events('event551');
                    }
                    this.$store.dispatch('addWatchList', this.router.itemId).then(response => {
                        if (response.data.success) {
                            this.isWatching = 1;
                            if (this.router.market === 'sell') {
                                CommonUtil.events('event309');
                            } else if(this.router.market === 'other') {
                                CommonUtil.events('event537');
                            } else {
                                CommonUtil.events('event54');
                            }
                        }
                    });
                }
            }
        },
        focusBidPrice(): void {
            this.bidPrice = this.bidPrice.replace(/,/g, '');
        },
        blurBidPrice(): void {
            this.bidPrice = this.numberFormat(this.bidPrice);
        },
        convertTimeLeft(timeleft: any): any {
            const matches: any = (/^(\d+)D(\d+)H(\d+)M(\d+)S$/.exec(timeleft));
            if (matches !== null) {
                return {
                    day: matches[1],
                    hour: matches[2],
                    minute: matches[3],
                    seconds: matches[4]
                };
            }
            return null;
        },
        watchStatus(): void {
            this.watchState = true;
        },
        watchStatusHidden(): void {
            this.watchState = false;
        },
        showShowHoldModal() {
            this.isShowHoldModal = true;
        },
        hideShowHoldModal() {
            this.isShowHoldModal = false;
        },
        hideBeginnerModal() {
            this.isClosedBeginnerModal = true;
        },
        apply(e: Event): void {
            e.preventDefault();
            if (this.applyValidate()) {
                this.$store.dispatch('clearBuyData');
                if (this.item.offerFlg === 1 && this.checkedNegotiate) {
                    const offerPrice: any = parseInt(this.offerPrice.toString().replace(/,/g, ''), 10);
                    this.$store.dispatch('setOfferPrice', offerPrice);
                    this.$store.dispatch('setOfferFlag', OFFER_FLAG_YES);
                } else {
                    this.$store.dispatch('setOfferPrice', this.item.startPrice);
                    this.$store.dispatch('setOfferFlag', OFFER_FLAG_NO);
                }
                if (!this.isLogin) {
                    // 未ログイン
                    this.$store.dispatch('setWatchEventLogin', 'loginDefault');
                    this.$store.dispatch('showModal', 'login');
                    EventHub.once(LOGIN_SUCCESS_EVENT, () => {
                        if (this.item.sameAccountFlg) {
                            this.$notify({
                                type: 'error',
                                group: 'notifications',
                                text: ERROR_MESSAGE_SAME_ACCOUNT_FLG
                            });
                        } else {
                            if (this.$store.state.Common.cardCheckFlg === '1') {
                                this.$store.dispatch('showModal', 'buy');
                            } else {
                                // クレジットカードなし
                                this.$store.dispatch('showModal', 'creditCard');
                            }
                        }

                    });
                } else {
                    // ログイン済み
                    if (this.$store.state.Common.cardCheckFlg === '1') {
                        this.$store.dispatch('showModal', 'buy');
                    } else {
                        // クレジットカードなし
                        this.$store.dispatch('showModal', 'creditCard');
                    }
                }
            }
        },
        applyValidate(): boolean
        {
            if (this.item.sameAccountFlg) {
                this.$notify({
                    type: 'error',
                    group: 'notifications',
                    text: ERROR_MESSAGE_SAME_ACCOUNT_FLG
                });
                return false;
            }

            if (this.item.offerFlg === 1 && this.checkedNegotiate) {
                if (this.offerPrice.toString().trim().length === 0) {
                    this.$notify({
                        type: 'error',
                        group: 'notifications',
                        text: '価格を入力してください。'
                    });

                    return false;
                }

                let offerPrice: any = this.offerPrice.toString().replace(/,/g, '');

                if (!/^\d+$/.test(offerPrice)) {
                    this.$notify({
                        type: 'error',
                        group: 'notifications',
                        text: '価格は数字のみで入力してください。'
                    });
                    return false;
                }

                offerPrice = parseInt(offerPrice, 10);

                if (offerPrice >= this.item.startPrice) {
                    this.$notify({
                        type: 'error',
                        group: 'notifications',
                        text: '価格交渉にてお申し込みいただく場合は、即決価格以下の金額でご入力ください。'
                    });
                    return false;
                }

                if (offerPrice > this.item.currentPrice) {
                    this.$notify({
                        type: 'error',
                        group: 'notifications',
                        text: `申請可能な金額(${this.item.currentPrice})を超えています。`
                    });
                    return false;
                }

                if (offerPrice < MIN_START_PRICE) {
                    this.$notify({
                        type: 'error',
                        group: 'notifications',
                        text: `交渉価格は${this.numberFormat(MIN_START_PRICE)}円以上をご入力ください。`
                    });
                    return false;
                }
            }

            return true;
        },
        focusStartPrice(): void {
            this.offerPrice = this.offerPrice.toString().replace(/,/g, '');
        },
        blurStartPrice(): void {
            this.offerPrice = this.numberFormat(this.offerPrice);
        },
        login(e: MouseEvent, $setEvent: string = 'loginDefault'): void {
            e.preventDefault();
            this.$store.dispatch('setWatchEventLogin', $setEvent);
            this.$store.dispatch('showModal', 'login');
        },
        addAutoBidPrice(value: any): void {
            let convertBidPrice;
            let price = '';
            this.errorBidPrice = '';

            if(!/[^\s]/.test(this.bidPrice)) {
                this.errorBidPrice = '入札金額を入力してください。';
            } else if(!/^[1-9](?:[\d,]+)?$/.test(this.bidPrice)) {
                this.errorBidPrice = '正しい形式で入力してください。';
            } else {
                if (!this.isAddCurrentPrice) {
                    // 入札額を加算
                    price = this.bidPrice.replace(/,/g, '');
                    convertBidPrice = parseInt(price, 10) + value;
                    this.bidPrice = this.numberFormat(convertBidPrice);
                    this.$emit('AddedCurrentPrice');
                } else {
                    convertBidPrice = this.bidPrice.replace(/,/g, '');
                    convertBidPrice = parseInt(convertBidPrice, 10) + value;
                    this.bidPrice = this.numberFormat(convertBidPrice);
                }

                clearTimeout(timeoutAddAutoBidPrice);

                switch (value) {
                    case this.tenThousand:
                        this.isPressedEasyBidPrice = this.tenThousand;
                        break;
                    case this.oneHundredThousand:
                        this.isPressedEasyBidPrice = this.oneHundredThousand;
                        break;
                    case this.oneMillion:
                        this.isPressedEasyBidPrice = this.oneMillion;
                        break;
                }

                // 1秒後初期値に戻す
                timeoutAddAutoBidPrice = setTimeout(() => {
                    this.isPressedEasyBidPrice = 0;
                },1000)
            }
        },
        preventCharacter(value: any): void {
            if (this.router.market === 'jp') {
                this.bidPrice = this.bidPrice.replace(/[^0-9]/g, '').trim();
            }
        },
        fireEvent(): void {
            const url = this.item.archiveUrl;
            if (this.router.market === 'jp') {
                CommonUtil.events('event535');
            } else if(this.router.market === 'other') {
                CommonUtil.events('event536');
            }
            window.open(url);
        },
        onConfirmModalFireEvent(): void {
            if (this.isLogin) {
                if (this.router.market === 'jp') {
                    CommonUtil.events('event575');
                } else if (this.router.market === 'other') {
                    CommonUtil.events('event576');
                }
            } else {
                if (this.router.market === 'jp') {
                    CommonUtil.events('event597');
                } else if (this.router.market === 'other') {
                    CommonUtil.events('event598');
                }
            }
        }

    },
    computed: {
        item(): any {            
            return this.$store.state.Item.item;
        },
        sellItemType(): string {
            if (this.item.offerFlg === 1 && this.checkedNegotiate) {
                return '交渉可能';
            }
            return '即決価格';
        },
        isShowBeginnerModal(): boolean {
            let referrer = document.referrer;
            if (
                (
                    this.router.market === 'jp' ||
                    this.router.market === 'other'
                ) &&
                !this.isShowedBeginnerModal &&
                !this.isEntered &&
                !this.isHold &&
                !this.isClosedBeginnerModal &&
                referrer.indexOf('/campaign/auction/') === -1 &&
                null === referrer.match(/mypage/)
            ) {
                CommonUtil.setCookie('show_beginner_modal', '1');
                return true;
            }

            return false;
        },
        sameAccountFlg(): boolean {
            return this.item.sameAccountFlg;
        },
        isHold(): boolean {
            if (this.isLogin && this.$store.state.Item.item.hold === 1) {
                return true;
            }
            return false;
        }
    },
    watch: {
        checkedNegotiate: {
            handler(value: any): void {
                if (!value) {
                    this.offerPrice = this.numberFormat(this.item.startPrice);
                }
            }
        }
    }
});
</script>

<template>
<div>
    <div class="dac-note--alert" v-if="isEnd && !itemTypeSell">
        <p class="dac-text--center"><span class="dac-text__alert">オークションは終了しました。</span></p>
    </div><!-- /.dac-note--alert -->
    <div class="dac-note--alert" v-if="sameAccountFlg">
        <p class="dac-text--center"><span class="dac-text__alert">ドメイン管理中のIDでは、お申し込みいただけません。</span></p>
    </div><!-- /.dac-note--alert -->
    <div class="dac-panel">
        <div class="dac-panel-body">
            <div class="dac-panel-content">
                <template v-if="isMobile">
                    <div :class="['dac-panel-content__lead' + (router.market === 'jp' || router.market === 'other' ? '--item' : '')]">
                        <a
                            href="javascript: void(0);"
                            @click="setWatching($event, 'loginWatching');onclickEvar('eVar10', router.market + 'auc_detail_watch_button_onclick_201904')"
                        >
                            <template v-if="router.market === 'jp' || router.market === 'other'">
                                <i v-if="router.market === 'jp'" :class="['dac-ico-heart-disabled__market--jp', isWatching === 1 ? 'is-watching' : isWatching === 0]" @mouseenter="watchStatus()" @mouseleave="watchStatusHidden()"></i>
                                <i v-else :class="['dac-ico-heart-disabled__market', isWatching === 1 ? 'is-watching' : isWatching === 0]" @mouseenter="watchStatus()" @mouseleave="watchStatusHidden()"></i>
                            </template>
                            <template v-else>
                                <i :class="['dac-ico-star--disabled', isWatching === 1 ? 'is-watching' : isWatching === 0]" @mouseenter="watchStatus()" @mouseleave="watchStatusHidden()"></i>
                            </template>
                        </a>
                        <template v-if="router.market === 'jp' || router.market === 'other'">
                            <p class="dac-panel-content__lead--text">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</p>
                        </template>
                        <template v-else>
                            {{ item.domainNameMl ? item.domainNameMl : item.domainName }}
                        </template>
                    </div>
                </template>
                <template v-else>
                    <div :class="['dac-panel-content__lead' + (router.market === 'jp' || router.market === 'other' ? '--item' : '')]">
                        <!-- TODO: 他マーケットに展開時にコードの整理と命名見直し -->
                        <template v-if="router.market === 'jp' || router.market === 'other'">
                            <a
                                href="javascript: void(0);"
                                @click="setWatching($event, 'loginWatching');onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_watchlist_tab_201904_b')"
                            >
                                <i v-if="router.market === 'jp'" :class="['dac-ico-heart-disabled__market--jp', isWatching === 1 ? 'is-watching' : isWatching === 0]" @mouseenter="watchStatus()" @mouseleave="watchStatusHidden()"></i>
                                <i v-else :class="['dac-ico-heart-disabled__market', isWatching === 1 ? 'is-watching' : isWatching === 0]" @mouseenter="watchStatus()" @mouseleave="watchStatusHidden()">
                                    <div class="dac-panel-content__bottom--jp is-pc" v-if="watchState === true">
                                        <p class="dac-btn--thin" v-if="isWatching === 0">ウォッチリストに追加</p>
                                        <p class="dac-btn--thin" v-else @click="setWatching($event, 'loginWatching')">追加済み</p>
                                    </div><!-- /.dac-panel-content__bottom -->
                                </i>
                            </a>
                        </template>
                        <template v-else>
                            <a
                                href="javascript: void(0);"
                                @click="setWatching($event);onclickEvar('eVar10', router.market + 'auc_mypage_bidlist_watchlist_tab_201904_b')"
                            >
                                <i :class="['dac-ico-heart-disabled', isWatching === 1 ? 'is-watching' : isWatching === 0]" @mouseenter="watchStatus()" @mouseleave="watchStatusHidden()">
                                    <div class="dac-panel-content__bottom is-pc" v-if="watchState">
                                        <p class="dac-btn--thin" v-if="isWatching === 0">ウォッチリストに追加</p>
                                        <p class="dac-btn--thin" @click="setWatching($event)" v-else>追加済み</p>
                                    </div><!-- /.dac-panel-content__bottom -->
                                </i>
                            </a>
                        </template>
                        <template v-if="router.market === 'jp' || router.market === 'other'">
                            <p class="dac-panel-content__lead--text">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</p>
                        </template>
                        <template v-else>
                            {{ item.domainNameMl ? item.domainNameMl : item.domainName }}
                        </template>
                    </div>
                </template>
                <template v-if="isMobile">
                    <template v-if="router.market === 'jp' || router.market === 'other'">
                        <div class="dac-note--detail">
                            <h2 class="dac-note__heading">&nbsp;</h2>
                            <div class="dac-note-point__content">
                                <div class="dac-note__content-sp">
                                    <div class="dac-note--detail__head">
                                        DA
                                        <span class="dac-tooltip-wrap">
                                            <a href="javascript: void(0);" class="dac-tooltip-trigger" @mouseover="tooltipOn()" @mouseleave="tooltipLeave()"><i class="dac-ico-tooltip--question"></i></a>
                                            <div :class="['dac-tooltip__content dac-tooltip__content--detail-da', tooltipActive ? 'is-active' : '']">
                                                <p>Webサイト（ドメイン名）が検索エンジンの順位において、どの程度評価されていたかを表す指標です。数が大きいほど評価が高かったことを示します。（SEOmozが提唱する用語）</p>
                                            </div>
                                        </span>
                                    </div>
                                    <div class="dac-note--detail__text"><div id="pie" class="dac-pie"></div>{{ item.DA }}</div>
                                </div>
                                <div class="dac-note__content-sp">
                                    <p class="dac-note--detail__head">外部リンク</p>
                                    <p class="dac-note--detail__text">{{ item.externalLinks }}</p>
                                </div>
                                <div class="dac-note__content-sp">
                                    <p class="dac-note--detail__head">ドメイン年齢</p>
                                    <p class="dac-note--detail__text">{{ item.domainAge }}</p>
                                </div>
                                <div class="dac-note__content-sp">
                                    <p class="dac-note--detail__head">ジャンル</p>
                                    <p class="dac-note--detail__text">{{ item.category }}</p>
                                </div>
                            </div>
                        </div><!-- /.dac-note--detail -->
                    </template>
                </template>
                <div v-if="itemTypeSell"><!-- 売買 -->
                    <template v-if="!isMobile">
                        <template v-if="router.market === 'jp' || router.market === 'other'">
                            <div class="dac-note--detail">
                                <h2 class="dac-note__heading">&nbsp;</h2>
                                <div class="dac-note-point__content">
                                    <div class="dac-note__content">
                                        <div class="dac-note--detail__head">
                                            DA
                                            <span class="dac-tooltip-wrap">
                                                <a href="javascript: void(0);" class="dac-tooltip-trigger" @mouseover="tooltipOn()" @mouseleave="tooltipLeave()"><i class="dac-ico-tooltip--question"></i></a>
                                                <div :class="['dac-tooltip__content dac-tooltip__content--detail-da', tooltipActive ? 'is-active' : '']">
                                                    <p>Webサイト（ドメイン名）が検索エンジンの順位において、どの程度評価されていたかを表す指標です。数が大きいほど評価が高かったことを示します。（SEOmozが提唱する用語）</p>
                                                </div>
                                            </span>
                                        </div>
                                        <div class="dac-note--detail__text"><div id="pie" class="dac-pie"></div>{{ item.DA }}</div>
                                    </div>
                                    <div class="dac-note__content">
                                        <p class="dac-note--detail__head">外部リンク</p>
                                        <p class="dac-note--detail__text">{{ item.externalLinks }}</p>
                                    </div>
                                    <div class="dac-note__content">
                                        <p class="dac-note--detail__head">ドメイン年齢</p>
                                        <p class="dac-note--detail__text">{{ item.domainAge }}</p>
                                    </div>
                                    <div class="dac-note__content">
                                        <p class="dac-note--detail__head">ジャンル</p>
                                        <p class="dac-note--detail__text">{{ item.category }}</p>
                                    </div>
                                </div>
                            </div><!-- /.dac-note--detail -->
                        </template>
                    </template>
                    <div class="dac-layout-auction">
                        <div class="dac-layout-auction__item">
                            <dl class="dac-table-list">
                                <dt class="dac-table-list__title">種類</dt>
                                <dd class="dac-table-list__content"><span class="dac-table-detail__id">{{ sellItemType }}</span></dd>
                            </dl>
                            <dl class="dac-table-list">
                                <dt class="dac-table-detail__title">更新期限日</dt>
                                <dd class="dac-table-detail__content"><span class="dac-table-detail__date">{{ item.expirationDate }}</span></dd>
                            </dl>
                            <dl class="dac-table-list">
                                <dt class="dac-table-detail__title">申し込み期限</dt>
                                <dd class="dac-table-detail__content"><span class="dac-table-detail__date">{{ convertJpDateFormat(item.endDate) }}</span><span class="dac-table-detail__time">{{ convertJpTimeFormat(item.endDate) }}</span></dd>
                            </dl>
                        </div>
                        <div class="dac-layout-auction__item">
                            <div class="dac-note-point--bid">
                                <div class="dac-note-point__inner">
                                    <form class="dac-form--bid" method="post" @submit="apply">
                                        <h4 class="dac-heading2--sm">価格</h4>
                                        <p v-if="(item.offerFlg !== 1 || !checkedNegotiate)" class="dac-form-input--lg__text"><b>{{ numberFormat(item.startPrice) }}</b>円</p>
                                        <div class="dac-form-input__label--row" v-if="checkedNegotiate">
                                            <input
                                                type="text"
                                                :class="['dac-form-input--lg-max', justLosing || ( item.offerFlg !== 1 || checkedNegotiate) ? 'is-active' : '']"
                                                v-model="offerPrice"
                                                @focus="focusStartPrice"
                                                @blur="blurStartPrice"
                                            ><p class="dac-form-input--lg__text">円</p>
                                        </div>
                                        <div class="dac-layout-footer">
                                            <div class="dac-layout-footer__top">
                                                <button
                                                    v-if="!isEnd"
                                                    class="dac-btn-secondary--lg-max"
                                                    type="submit"
                                                    @click="onclickEvar('eVar10', router.market + 'auc_detail_apply_button_onclick_201904')"
                                                >申し込む</button>
                                            </div>
                                            <label for="checkedNegotiate" class="dac-form-check__label--negotiate" v-if="item.offerFlg === 1">
                                                <input type="checkbox" id="checkedNegotiate" class="dac-form-check" v-model="checkedNegotiate" @change="onclickEvar('eVar10', router.market + 'auc_detail_pricenegotiation_check_onclick_201904')">
                                                <i class="dac-ico-check"></i>
                                                <span class="dac-form-check__text">価格を交渉する</span>
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!-- 売買 END -->
                <div v-else><!-- オークション -->
                    <div class="dac-layout-auction">
                        <template v-if="isMobile">
                            <div class="dac-layout-auction__item">
                                <h4 class="dac-heading1--sm">オークション状況</h4>
                                <dl class="dac-table-list">
                                    <dt class="dac-table-list__title">現在価格</dt>
                                    <dd class="dac-table-list__content"><span class="dac-table-list__price">{{ numberFormat(item.currentPrice) }}</span><span class="dac-table-list__unit">円</span></dd>
                                </dl>
                                <dl class="dac-table-list" v-if="item.autoBidPrice !== null">
                                    <dt class="dac-table-list__title">自動入札価格</dt>
                                    <dd class="dac-table-list__content"><span class="dac-table-list__price">{{ numberFormat(item.autoBidPrice) }}</span><span class="dac-table-list__unit">円</span></dd>
                                </dl>
                                <dl class="dac-table-list">
                                    <dt class="dac-table-list__title">残り時間</dt>
                                    <dd class="dac-table-list__content">
                                        <span class="dac-table-list__time" v-if="isEnd">オークションは終了しました</span>
                                        <template v-else>
                                                <span class="dac-table-list__date" v-if="convertTimeLeft(item.timeleft)">
                                                    {{ convertTimeLeft(item.timeleft).day }}<span class="dac-table-list__unit">日</span>{{ convertTimeLeft(item.timeleft).hour }}<span class="dac-table-list__unit">時間</span>{{ convertTimeLeft(item.timeleft).minute }}<span class="dac-table-list__unit">分</span>{{ convertTimeLeft(item.timeleft).seconds }}<span class="dac-table-list__unit">秒</span>
                                                </span>
                                            <span class="dac-table-list__date" v-else>-</span>
                                        </template>
                                    </dd>
                                </dl>
                                <dl class="dac-table-list">
                                    <dt class="dac-table-list__title">入札件数</dt>
                                    <dd class="dac-table-list__content"><span class="dac-table-list__num">{{ numberFormat(item.bidCount) }}</span><span class="dac-table-list__unit">件</span></dd>
                                </dl>
                                <div class="dac-table-accordion" v-if="!isDomainLevelLower">
                                    <div class="dac-table-accordion__content is-active">
                                        <dl class="dac-table-detail">
                                            <dt class="dac-table-detail__title">開始価格</dt>
                                            <dd class="dac-table-detail__content"><span class="dac-table-detail__price">{{ numberFormat(item.startPrice) }}</span>円</dd>
                                        </dl>
                                        <dl class="dac-table-detail">
                                            <dt class="dac-table-detail__title">開始時間</dt>
                                            <dd class="dac-table-detail__content"><span class="dac-table-detail__date">{{ convertJpDateFormat(item.startDate) }}</span><span class="dac-table-detail__time">{{ convertJpTimeFormat(item.startDate) }}</span></dd>
                                        </dl>
                                        <dl class="dac-table-detail">
                                            <dt class="dac-table-detail__title">終了時間</dt>
                                            <dd class="dac-table-detail__content"><span class="dac-table-detail__date">{{ convertJpDateFormat(item.endDate) }}</span><span class="dac-table-detail__time">{{ convertJpTimeFormat(item.endDate) }}</span></dd>
                                        </dl>
                                        <dl class="dac-table-detail">
                                            <dt class="dac-table-detail__title">最高額入札者</dt>
                                            <dd class="dac-table-detail__content">
                                                <template v-if="!isLogin">
                                                    <a class="dac-text__link" href="javascript: void(0);" @click="login($event)">ログインして確認する</a>
                                                </template>
                                                <template v-else>
                                                    <template v-if="item.displayName !== null">
                                                        <div v-if="isHold" class="dac-table-detail__holder">
                                                            <p class="dac-note--winning__text--small">
                                                                <span class="dac-ico-holder"><img :src="assets('images/icon-smile.svg')" alt=""></span>
                                                                お客様
                                                            </p>
                                                        </div>
                                                        <div v-else class="dac-table-detail__holder">
                                                            <p class="dac-note--alert__text--small">
                                                                <span class="dac-ico-holder"><img :src="assets('images/icon-lose.svg')" alt=""></span>
                                                                他者様
                                                            </p>
                                                        </div>
                                                    </template>
                                                </template>
                                            </dd>
                                        </dl>
                                        <dl class="dac-table-detail">
                                            <dt class="dac-table-detail__title">最低入札価格</dt>
                                            <dd class="dac-table-detail__content"><span class="dac-table-detail__price">{{ numberFormat(item.bidMinPrice) }}</span>円</dd>
                                        </dl>
                                    </div><!-- /.dac-table-accordion__content -->
                                </div><!-- /.dac-table-accordion -->
                                <template v-if="isMobile">
                                    <template v-if="router.market === 'sell'">
                                        <div class="dac-note-point--blur is-sell_other">
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <i class="dac-ico-recommend"><span class="is-sp">おすすめ</span></i>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs">予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="router.market === 'jp' || router.market === 'other'">
                                            <a
                                                href="javascript: void(0);"
                                                class="dac-note-point--blur"
                                                @click="bidModalActive();onclickEvar('eVar10', 'autobid_guide')"
                                                v-if="!isDomainLevelLower"
                                            >
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <template v-if="router.market === 'jp' || router.market === 'other'">
                                                        <i class="dac-ico-recommend__new">
                                                            <span class="is-sp">ガイド</span>
                                                        </i>
                                                    </template>
                                                    <template v-else>
                                                        <i class="dac-ico-recommend">
                                                            <span class="is-sp">おすすめ</span>
                                                        </i>
                                                    </template>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs" v-if="router.market === 'jp' || router.market === 'other'">入札した金額を上限として、他者が入札すると自動的に最低入札単価で再入札するしくみです。価格が上昇しなかった場合、入札金額ではなくオークション終了価格で落札可能です。<strong>※現在の入札単位は￥{{ numberFormat(item.bidIncrement) }}です。</strong></p>
                                                    <p class="dac-text--xs" v-else>予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                            <div class="dac-note-point__footer">
                                                <p class="dac-text__link">詳しく見る</p>
                                            </div>
                                            </a><!-- /.dac-note-point -->
                                        </template>
                                        <template v-else>
                                            <a
                                                href="javascript: void(0);"
                                                class="dac-note-point--blur"
                                                @click="bidModalActive();onclickEvar('eVar10', router.market + 'auc_detail_automaticbid_open_onclick_201904')"
                                                v-if="!isDomainLevelLower"
                                            >
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <i class="dac-ico-recommend">
                                                        <span class="is-sp" v-if="router.market === 'jp' || router.market === 'other'">ガイド</span>
                                                        <span class="is-sp" v-else>おすすめ</span>
                                                    </i>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs" v-if="router.market === 'jp' || router.market === 'other'">入札した金額を上限として、他者が入札すると自動的に最低入札単価で再入札するしくみです。価格が上昇しなかった場合、入札金額ではなくオークション終了価格で落札可能です。<strong>※現在の入札単位は￥{{ numberFormat(item.bidIncrement) }}です。</strong></p>
                                                    <p class="dac-text--xs" v-else>予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                            <div class="dac-note-point__footer">
                                                <p class="dac-text__link">詳しく見る</p>
                                            </div>
                                            </a><!-- /.dac-note-point -->
                                        </template>
                                    </template>
                                </template>
                            </div><!-- /.dac-layout-auction__item -->
                        </template>
                        <div :class="['dac-layout-auction__item', isEnd ? 'is-disabled' : '']">
                            <template v-if="router.market === 'jp' || router.market === 'other'">
                                <template v-if="item.hasOwnProperty('archiveDisplay') && item.archiveDisplay === '1'">
                                    <p :class="['dac-text--right', isMobile ? 'dac-text--md' : 'dac-text--lg']">
                                        <a
                                            href="javascript: void(0);"
                                            @click="fireEvent()"
                                            class="dac-text__link"
                                        >
                                            <b>サイト運用履歴（外部サイト）を確認する</b>
                                        </a>
                                    </p>
                                </template>
                            </template>
                            <div class="dac-note--alert with-icon dac-icon" v-if="justLosing || (!justLosing && losing && showNoticeLosing)">
                                <div class="dac-note__inner" v-if="!isMobile">
                                    <div class="dac-note__inner--left">
                                        <div class="dac-note__icon">
                                            <img :src="assets('images/icon-lose.svg')" alt="">
                                        </div>
                                        <p class="dac-note--alert__text--small">
                                            お客様の入札額を上回る入札が行われました。
                                        </p>
                                    </div>
                                    <a id='closeLose' onclick='this.parentNode.parentNode.remove(); return false;'>x</a>
                                </div>
                                <div class="dac-note__inner" v-else>
                                    <div class="dac-note__icon">
                                        <img :src="assets('images/icon-lose.svg')" alt="">
                                    </div>
                                    <p class="dac-note--alert__text--small">
                                        お客様の入札額を上回る入札が行われました。
                                    </p>
                                </div>
                            </div><!-- /.dac-note--alert -->
                            <div class="dac-note--winning with-icon dac-icon" v-if="isHold && showNoticeHold">
                                <div class="dac-note__inner" v-if="!isMobile">
                                    <div class="dac-note__inner--left">
                                        <div class="dac-note__icon">
                                            <img :src="assets('images/icon-smile.svg')" alt="">
                                        </div>
                                        <p class="dac-note--winning__text--small">
                                            お客様が現在の最高額入札者です！
                                            <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                                <br>
                                                <a
                                                    :href="'/campaign/' + (router.market === 'gtld' ? 'gtld' : '') + 'auction/?btn_id=' + router.market + 'auc_detail_auctionresult_win_top_201907'"
                                                    class="dac-note--winning__text--small__link"
                                                >
                                                    その他ドメイン一覧も見る
                                                </a>
                                            </template>
                                        </p>
                                    </div>
                                    <a id='close' onclick='this.parentNode.parentNode.remove(); return false;'>x</a>
                                </div>
                                <div class="dac-note__inner" v-else>
                                    <div class="dac-note__icon">
                                        <img :src="assets('images/icon-smile.svg')" alt="">
                                    </div>
                                    <p class="dac-note--winning__text--small">
                                        お客様が現在の最高額入札者です！
                                        <template v-if="router.market === 'jp' || router.market === 'gtld'">
                                            <br>
                                            <a
                                                :href="'/campaign/' + (router.market === 'gtld' ? 'gtld' : '') + 'auction/?btn_id=' + router.market + 'auc_detail_auctionresult_win_top_201907'"
                                                class="dac-note--winning__text--small__link"
                                            >
                                                その他ドメイン一覧も見る
                                            </a>
                                        </template>
                                    </p>
                                </div>
                            </div><!-- /.dac-note-notice -->
                            <div :class="['dac-note-point--bid', justLosing || ( !justLosing && losing && showNoticeLosing) ? 'is-alert' : '']">
                                <div class="dac-note-point__inner">
                                    <form class="dac-form--bid" method="post" @submit="bidSubmit">
                                        <h4 class="dac-heading2--sm">入札額</h4>
                                        <label class="dac-form-input__label--row" for="bidPrice">
                                            <input
                                                class="dac-form-input--lg-max"
                                                :class="{ 'is-active': isPressedEasyBidPrice }"
                                                type="text"
                                                name="bidPrice"
                                                id="bidPrice"
                                                v-model="bidPrice"
                                                size="60"
                                                maxlength="11"
                                                @focus="focusBidPrice"
                                                @blur="blurBidPrice"
                                                @input="preventCharacter"
                                                :disabled="isEnd"
                                            >
                                            <span class="dac-form-input--lg__text">円</span>
                                        </label>
                                        <p class="dac-text--sm" v-if="errorBidPrice"><span class="dac-text__alert">{{ errorBidPrice }}</span></p>

                                        <template v-if="isDomainLevelLower">
                                            <div class="dac-layout-easy-bid" v-if="!isMobile">
                                                <span class="dac-layout-easy-bid__item">現在価格</span>
                                                <span class="dac-layout-easy-bid__item"><span class="dac-layout-easy-bid__item--price">{{ numberFormat(item.currentPrice) }}円</span></span>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="dac-layout-easy-bid pb0" v-if="!isMobile">
                                                <span class="dac-layout-easy-bid__item">現在価格</span>
                                                <span class="dac-layout-easy-bid__item"><span class="dac-layout-easy-bid__item--price">{{ numberFormat(item.currentPrice) }}円</span></span>
                                            </div>
                                            <div class="dac-layout-easy-bid" :class="{ 'pb12': !isMobile}">
                                                <div class="dac-layout-easy-bid__item">
                                                    <span v-if="!isMobile">現在価格から</span>
                                                    <span v-else>上記価格に</span>
                                                </div>
                                                <div class="dac-layout-easy-bid__item">
                                                    <button
                                                        class="dac-btn--thin-row"
                                                        :class="{ 'is-active': isPressedEasyBidPrice === tenThousand }"
                                                        type="button"
                                                        @click="addAutoBidPrice(tenThousand);onclickEvar('eVar10', router.market + 'auc_detail_bid_1button_onclick_201904')"
                                                    >
                                                        <span class="dac-btn--thin-row__text">＋ 1万円</span>
                                                    </button>
                                                </div>
                                                <div class="dac-layout-easy-bid__item">
                                                    <button
                                                        class="dac-btn--thin-row"
                                                        :class="{ 'is-active': isPressedEasyBidPrice === oneHundredThousand }"
                                                        type="button"
                                                        @click="addAutoBidPrice(oneHundredThousand);onclickEvar('eVar10', router.market + 'auc_detail_bid_10button_onclick_201904')"
                                                    >
                                                        <span class="dac-btn--thin-row__text">＋ 10万円</span>
                                                    </button>
                                                </div>
                                                <div class="dac-layout-easy-bid__item">
                                                    <button
                                                        class="dac-btn--thin-row"
                                                        :class="{ 'is-active': isPressedEasyBidPrice === oneMillion}"
                                                        type="button"
                                                        @click="addAutoBidPrice(oneMillion);onclickEvar('eVar10', router.market + 'auc_detail_bid_100button_onclick_201904')"
                                                    >
                                                        <span class="dac-btn--thin-row__text">＋ 100万円</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </template>
                                        <div class="dac-layout-footer">
                                            <div v-if="isMobile" class="dac-layout-footer__top">
                                                <button
                                                    class="dac-btn-secondary--lg-max"
                                                    :class="{'is-disabled': isEnd}"
                                                    type="submit"
                                                    @click="onclickEvar('eVar10', router.market + 'auc_detail_bid_button_onclick_201904'), onConfirmModalFireEvent()"
                                                >入札する</button>
                                            </div>
                                            <div v-else class="dac-layout-footer__top">
                                                <button
                                                    class="dac-btn-secondary--lg-max"
                                                    :class="{'is-disabled': isEnd}"
                                                    type="submit"
                                                    @click="onclickEvar('eVar10', router.market + 'auc_detail_bid_button_onclick_201904'), onConfirmModalFireEvent()"
                                                >入札する</button>
                                            </div>
                                        </div><!-- /.dac-layout-footer -->
                                        <div class="dac-layout-time-left" v-if="!isMobile && (router.market === 'jp' || router.market === 'other')">
                                            <span class="dac-layout-time-left__end" v-if="isEnd">オークションは終了しました</span>
                                            <template v-else>
                                                <span class="dac-layout-time-left__date" v-if="convertTimeLeft(item.timeleft)">
                                                    <i class="dac-ico-time-left"></i>残り時間 あと<span class="dac-layout-time-left__unit" v-if="convertTimeLeft(item.timeleft).day !== '0'">{{ convertTimeLeft(item.timeleft).day }} 日</span><span class="dac-layout-time-left__unit">{{ convertTimeLeft(item.timeleft).hour }} 時間</span><span class="dac-layout-time-left__unit">{{ convertTimeLeft(item.timeleft).minute }} 分</span>
                                                </span>
                                                <span class="dac-layout-time-left__date" v-else>-</span>
                                            </template>
                                        </div>
                                    </form>
                                </div>
                            </div><!-- /.dac-note-point -->
                            <template v-if="router.market === 'jp' || router.market === 'other'">
                                <div class="dac-note--recommend">
                                    ※Whois情報公開代行は無料で付帯します。
                                    <span class="dac-tooltip-wrap">
                                        <i class="dac-ico-tooltip--question" @mouseover="tooltipOn('recommendInfo')" @mouseleave="tooltipLeave('recommendInfo')"></i>
                                        <div :class="['dac-tooltip__content dac-tooltip__content--detail-da__recommend', tooltipRecommendActive ? 'is-active' : '']">
                                            <p>ドメインの登録者情報等を「お名前.com」の情報に代えて公開することができるサービスです。これによりドメイン登録者のプライバシーが保護されます。</p>
                                        </div>
                                    </span>
                                </div>
                            </template>
                            <template v-if="!isMobile">
                                <template v-if="router.market === 'jp' || router.market === 'other'">
                                    <div class="dac-note--detail">
                                        <h2 class="dac-note__heading">&nbsp;</h2>
                                        <div class="dac-note-point__content">
                                            <div class="dac-note__content">
                                                <div class="dac-note--detail__head">
                                                    DA
                                                    <span class="dac-tooltip-wrap">
                                                        <a href="javascript: void(0);" class="dac-tooltip-trigger" @mouseover="tooltipOn()" @mouseleave="tooltipLeave()"><i class="dac-ico-tooltip--question"></i></a>
                                                        <div :class="['dac-tooltip__content dac-tooltip__content--detail-da', tooltipActive ? 'is-active' : '']">
                                                            <p>Webサイト（ドメイン名）が検索エンジンの順位において、どの程度評価されていたかを表す指標です。数が大きいほど評価が高かったことを示します。（SEOmozが提唱する用語）</p>
                                                        </div>
                                                    </span>
                                                </div>
                                                <div class="dac-note--detail__text"><div id="pie" class="dac-pie"></div>{{ item.DA }}</div>
                                            </div>
                                            <div class="dac-note__content">
                                                <p class="dac-note--detail__head">外部リンク</p>
                                                <p class="dac-note--detail__text">{{ item.externalLinks }}</p>
                                            </div>
                                            <div class="dac-note__content">
                                                <p class="dac-note--detail__head">ドメイン年齢</p>
                                                <p class="dac-note--detail__text">{{ item.domainAge }}</p>
                                            </div>
                                            <div class="dac-note__content">
                                                <p class="dac-note--detail__head">ジャンル</p>
                                                <p class="dac-note--detail__text">{{ item.category }}</p>
                                            </div>
                                        </div>
                                    </div><!-- /.dac-note--detail -->
                                </template>
                                <template v-if="router.market === 'sell'">
                                    <div class="dac-note-point--blur is-sell_other" v-if="!isMobile">
                                        <div class="dac-note-point__content content-other">
                                            <div class="dac-note-point__label">
                                                <i class="dac-ico-recommend"><span class="is-sp">おすすめ</span></i>
                                                <p class="dac-text">自動入札</p>
                                            </div>
                                            <div class="dac-note-point__detail">
                                                <p class="dac-text--xs">予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="dac-note-point--blur is-sell_other" v-else>
                                        <div class="dac-note-point__content content-other">
                                            <div class="dac-note-point__label">
                                                <i class="dac-ico-recommend"><span class="is-sp">おすすめ</span></i>
                                                <p class="dac-text">自動入札</p>
                                            </div>
                                            <div class="dac-note-point__detail">
                                                <p class="dac-text--xs">予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-else>
                                    <template v-if="!isDomainLevelLower">
                                        <template v-if="router.market === 'jp' || router.market === 'other'">
                                            <a
                                                href="javascript: void(0);"
                                                class="dac-note-point--blur"
                                                @click="bidModalActive();onclickEvar('eVar10', 'autobid_guide')"
                                            >
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <i class="dac-ico-recommend__new">
                                                        <span class="is-sp" v-if="router.market === 'jp' || router.market === 'other'">ガイド</span>
                                                        <span class="is-sp" v-else>おすすめ</span>
                                                    </i>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs__new" v-if="router.market === 'jp' || router.market === 'other'">入札した金額を上限として、他者が入札すると自動的に最低入札単価で再入札するしくみです。価格が上昇しなかった場合、入札金額ではなくオークション終了価格で落札可能です。<strong>※現在の入札単位は￥{{ numberFormat(item.bidIncrement) }}です。</strong></p>
                                                    <p class="dac-text--xs" v-else>予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                            <div class="dac-note-point__footer">
                                                <p class="dac-text__link">詳しく見る</p>
                                            </div>
                                        </a><!-- /.dac-note-point -->
                                        </template>
                                        <template v-else>
                                            <a
                                                href="javascript: void(0);"
                                                class="dac-note-point--blur"
                                                @click="bidModalActive();onclickEvar('eVar10', router.market + 'auc_detail_automaticbid_open_onclick_201904')"
                                            >
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                <template v-if="router.market === 'jp' || router.market === 'other'">
                                                    <i class="dac-ico-recommend__new">
                                                        <span class="is-sp">ガイド</span>
                                                    </i>
                                                </template>
                                                <template v-else>
                                                    <i class="dac-ico-recommend">
                                                        <span class="is-sp">おすすめ</span>
                                                    </i>
                                                </template>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs__new" v-if="router.market === 'jp' || router.market === 'other'">入札した金額を上限として、他者が入札すると自動的に最低入札単価で再入札するしくみです。価格が上昇しなかった場合、入札金額ではなくオークション終了価格で落札可能です。<strong>※現在の入札単位は￥{{ numberFormat(item.bidIncrement) }}です。</strong></p>
                                                    <p class="dac-text--xs" v-else>予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                            <div class="dac-note-point__footer">
                                                <p class="dac-text__link">詳しく見る</p>
                                            </div>
                                        </a><!-- /.dac-note-point -->
                                        </template>
                                    </template>
                                </template>
                            </template>
                        </div><!-- /.dac-layout-auction__item -->
                        <template v-if="!isMobile">
                            <div class="dac-layout-auction__item is-pc">
                                <h4 class="dac-heading1--sm">オークション状況</h4>
                                <div class="dac-table">
                                    <div class="dac-table-accordion is-box ">
                                        <div class="dac-table-accordion__content is-active">
                                            <dl class="dac-table-detail">
                                                <template v-if="router.market !== 'jp' && router.market !== 'other'">
                                                    <dt class="dac-table-detail__title">残り時間</dt>
                                                    <dd class="dac-table-detail__content">
                                                        <span class="dac-table-list__time" v-if="isEnd">オークションは終了しました</span>
                                                        <template v-else>
                                                            <span class="dac-table-list__date" v-if="convertTimeLeft(item.timeleft)">
                                                                {{ convertTimeLeft(item.timeleft).day }}<span class="dac-table-list__unit">日</span>{{ convertTimeLeft(item.timeleft).hour }}<span class="dac-table-list__unit">時間</span>{{ convertTimeLeft(item.timeleft).minute }}<span class="dac-table-list__unit">分</span>
                                                            </span>
                                                            <span class="dac-table-list__date" v-else>-</span>
                                                        </template>
                                                    </dd>
                                                </template>
                                            </dl>
                                            <dl class="dac-table-detail">
                                                <dt class="dac-table-detail__title">入札件数</dt>
                                                <dd class="dac-table-detail__content"><span class="dac-table-list__num">{{ numberFormat(item.bidCount) }}</span><span class="dac-table-list__unit">件</span></dd>
                                            </dl>
                                            <dl class="dac-table-detail">
                                                <dt class="dac-table-detail__title">開始価格</dt>
                                                <dd class="dac-table-detail__content"><span class="dac-table-detail__price">{{ numberFormat(item.startPrice) }}</span>円</dd>
                                            </dl>
                                            <dl class="dac-table-detail">
                                                <dt class="dac-table-detail__title">開始時間</dt>
                                                <dd class="dac-table-detail__content"><span class="dac-table-detail__date">{{ convertJpDateFormat(item.startDate) }}</span><span class="dac-table-detail__time">{{ convertJpTimeFormat(item.startDate) }}</span></dd>
                                            </dl>
                                        </div><!-- /.dac-table-accordion__content -->
                                    </div><!-- /.dac-table-accordion -->
                                    <div class="dac-table-border"></div>
                                    <div class="dac-table-accordion is-box">
                                        <div class="dac-table-accordion__content is-active">
                                            <dl class="dac-table-detail">
                                                <dt class="dac-table-detail__title">終了時間</dt>
                                                <dd class="dac-table-detail__content"><span class="dac-table-detail__date">{{ convertJpDateFormat(item.endDate) }}</span><span class="dac-table-detail__time">{{ convertJpTimeFormat(item.endDate) }}</span></dd>
                                            </dl>
                                            <dl class="dac-table-detail">
                                                <dt class="dac-table-detail__title">最高額入札者</dt>
                                                <dd class="dac-table-detail__content">
                                                    <template v-if="!isLogin">
                                                        <a class="dac-text__link" href="javascript: void(0);" @click="login($event)">ログインして確認する</a>
                                                    </template>
                                                    <template v-else>
                                                        <template v-if="item.displayName !== null">
                                                            <div v-if="isHold" class="dac-table-detail__holder">
                                                                <p class="dac-note--winning__text--small">
                                                                    <span class="dac-ico-holder"><img :src="assets('images/icon-smile.svg')" alt=""></span>
                                                                    お客様
                                                                </p>
                                                            </div>
                                                            <div v-else class="dac-table-detail__holder">
                                                                <p class="dac-note--alert__text--small">
                                                                    <span class="dac-ico-holder"><img :src="assets('images/icon-lose.svg')" alt=""></span>
                                                                    他者様
                                                                </p>
                                                            </div>
                                                        </template>
                                                    </template>
                                                </dd>
                                            </dl>
                                            <dl class="dac-table-detail">
                                                <dt class="dac-table-detail__title">最低入札価格</dt>
                                                <dd class="dac-table-detail__content"><span class="dac-table-detail__price">{{ numberFormat(item.bidMinPrice) }}</span>円</dd>
                                            </dl>
                                        </div><!-- /.dac-table-accordion__content -->
                                    </div><!-- /.dac-table-accordion -->
                                </div>
                                <template v-if="isMobile">
                                    <template v-if="router.market === 'sell'">
                                        <div class="dac-note-point--blur is-sell_other">
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <i class="dac-ico-recommend"><span class="is-sp">おすすめ</span></i>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs">予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <template v-if="router.market === 'jp' || router.market === 'other'">
                                            <a
                                                href="javascript: void(0);"
                                                class="dac-note-point--blur"
                                                @click="bidModalActive();onclickEvar('eVar10', 'autobid_guide')"
                                            >
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <template v-if="router.market === 'jp' || router.market === 'other'">
                                                        <i class="dac-ico-recommend__new">
                                                            <span class="is-sp">ガイド</span>
                                                        </i>
                                                    </template>
                                                    <template v-else>
                                                        <i class="dac-ico-recommend">
                                                            <span class="is-sp">おすすめ</span>
                                                        </i>
                                                    </template>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs" v-if="router.market === 'jp' || router.market === 'other'">入札した金額を上限として、他者が入札すると自動的に最低入札単価で再入札するしくみです。価格が上昇しなかった場合、入札金額ではなくオークション終了価格で落札可能です。<strong>※現在の入札単位は￥{{ numberFormat(item.bidIncrement) }}です。</strong></p>
                                                    <p class="dac-text--xs" v-else>予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                            <div class="dac-note-point__footer">
                                                <p class="dac-text__link">詳しく見る</p>
                                            </div>
                                        </a><!-- /.dac-note-point -->
                                        </template>
                                        <template v-else>
                                            <a
                                                href="javascript: void(0);"
                                                class="dac-note-point--blur"
                                                @click="bidModalActive();onclickEvar('eVar10',  router.market + 'auc_detail_automaticbid_open_onclick_201904')"
                                            >
                                            <div class="dac-note-point__content">
                                                <div class="dac-note-point__label">
                                                    <template v-if="router.market === 'jp' || router.market === 'other'">
                                                        <i class="dac-ico-recommend__new">
                                                            <span class="is-sp">ガイド</span>
                                                        </i>
                                                    </template>
                                                    <template v-else>
                                                        <i class="dac-ico-recommend">
                                                            <span class="is-sp">おすすめ</span>
                                                        </i>
                                                    </template>
                                                    <p class="dac-text">自動入札</p>
                                                </div>
                                                <div class="dac-note-point__detail">
                                                    <p class="dac-text--xs" v-if="router.market === 'jp' || router.market === 'other'">入札した金額を上限として、他者が入札すると自動的に最低入札単価で再入札するしくみです。価格が上昇しなかった場合、入札金額ではなくオークション終了価格で落札可能です。<strong>※現在の入札単位は￥{{ numberFormat(item.bidIncrement) }}です。</strong></p>
                                                    <p class="dac-text--xs" v-else>予算の上限で入札しておくと、他の人が入札してきた場合に、上限金額の範囲内で自動的に再入札します。</p>
                                                </div>
                                            </div>
                                            <div class="dac-note-point__footer">
                                                <p class="dac-text__link">詳しく見る</p>
                                            </div>
                                        </a><!-- /.dac-note-point -->
                                        </template>
                                    </template>
                                </template>
                            </div><!-- /.dac-layout-auction__item -->
                        </template>
                    </div><!-- /.dac-layout-auction -->
                </div><!-- オークション END -->
            </div><!-- /.dac-panel-content -->
        </div><!-- /.dac-panel-body -->
        <div class="dac-note-point__content" v-if="router.market === 'jp' && isDomainLevelLower">
            <p v-if="item.description">{{ item.description }}</p>
        </div><!-- /.dac-note-point__content -->
    </div><!-- ./dac-panel -->
    <aside class="dac-modal" :class="{ 'is-fixed': isBidDescribe }">
        <div class="dac-modal__content">
            <template v-if="router.market === 'jp' || router.market === 'other'">
                <a
                    href="javascript: void(0);"
                    class="dac-modal-close"
                    @click="bidModalActive()"
                ><span class="dac-modal-close__icon"></span></a>
            </template>
            <template v-else>
                <a
                    href="javascript: void(0);"
                    class="dac-modal-close"
                    @click="bidModalActive();onclickEvar('eVar10', router.market + 'auc_detail_automaticbid_close_onclick_201904')"
                ><span class="dac-modal-close__icon"></span></a>
            </template>
            <div class="dac-modal__heading">
                <h3 class="dac-heading-point-secondary--inline">自動入札とは？</h3>
            </div><!-- /.dac-modal__heading -->
            <div class="dac-modal__row">
                <div class="dac-note--shadow" v-if="router.market === 'jp' || router.market === 'other'">
                    <p class="dac-text--md">入札した金額を上限として、<br class="is-pc">他者が入札すると自動的に最低入札単価で再入札するしくみです。</p>
                </div><!-- /.dac-note -->
                <div class="dac-note--shadow" v-else>
                    <p class="dac-text--md">予算の上限で入札しておくと他の人が入札してきた場合に、<br class="is-pc">上限金額の範囲内で自動的に再入札をしてくれる仕組みのことです。</p>
                </div><!-- /.dac-note -->
                <ul class="dac-text-indent--md">
                    <li class="dac-text-indent__item"><span class="dac-text-indent__img"><img :src="assets('images/img_merit01.png')" alt="メリット1" /></span>入札した金額までは自動更新のため、価格確認・入札の手間が省けます！</li>
                    <li class="dac-text-indent__item" v-if="router.market === 'jp' || router.market === 'other'"><span class="dac-text-indent__img"><img :src="assets('images/img_merit02.png')" alt="メリット2" /></span>他者の入札が無かった場合はオークション終了価格で落札可能です！</li>
                    <li class="dac-text-indent__item" v-else ><span class="dac-text-indent__img"><img :src="assets('images/img_merit02.png')" alt="メリット2" /></span>価格更新が確認できなかった場合など、再入札漏れが防げる！</li>
                </ul>
            </div><!-- /.dac-modal__row -->
            <div class="dac-modal__heading">
                <h3 class="dac-heading-point-secondary--inline">自動入札の方法</h3>
            </div><!-- /.dac-modal__heading -->
            <div class="dac-modal-image__flow" v-if="router.market === 'jp' || router.market === 'other'">
                <img class="is-vertical" :src="assets('images/img_jp-auc-flow-new.png')" alt="自動入札の方法" />
                <img class="is-stackable" :src="assets('images/img_jp-auc-flow-sp-new.png')" alt="自動入札の方法" />
            </div><!-- /.dac-modal__image -->
            <div class="dac-modal-image__flow" v-if="router.market === 'gtld'">
                <img class="is-vertical" :src="assets('images/img_bid-flow.png')" alt="自動入札の方法" />
                <img class="is-stackable" :src="assets('images/img_bid-flow-sp.png')" alt="自動入札の方法" />
            </div><!-- /.dac-modal__image -->
        </div>
    </aside><!-- /.dac-modal -->
    <div class="dac-modal__overlay" @click="bidModalActive()" :class="{ 'is-fixed': isBidDescribe }"></div>
    <BeginnerModalModule v-if="isShowBeginnerModal" @hideBeginnerModal="hideBeginnerModal"></BeginnerModalModule>
</div>
</template>