<script lang="ts">
import { defineComponent, reactive, toRefs } from 'vue';
import CommonUtil from '../../libs/CommonUtil';
import EventHub from '../../libs/EventHub';
import AgreementSellModule from './AgreementSellModule.vue';
import { TRAFFIC_TYPE_AUCTION } from '../../libs/Mutations';
import Mixin from '../../libs/Mixin';

interface IState {
    tooltipActive: boolean,
    DA: number,
    maxDA: number
}

export default defineComponent({
    mixins: [Mixin],
    components: {
        AgreementSellModule
    },
    setup() {
        const state = reactive<IState>({
            tooltipActive: false,
            DA: 0,
            maxDA: 0
        });

        return {
            ...toRefs(state)
        };
    },
    async created() {
        let bid: number = this.$store.state.Bid.bidPrice;
        if (this.$store.state.Item.item.bidMinPrice > bid) {
            bid = this.$store.state.Item.item.bidMinPrice;
        }
        const params: any = {
            itemId: this.router.itemId,
            bid: bid
        };
        await this.$store.dispatch('getBidFairPrice', params).then(response => {
            if (response.data.success) {
                if (this.router.market === 'jp' || this.router.market === 'other') {
                    let targetPie: HTMLElement = <HTMLElement>document.getElementById('pie');
                    // DAの最大値
                    this.maxDA = Number(this.$store.state.Item.item.maxDA);
                    // itemのDA
                    this.DA = Number(this.$store.state.Item.item.DA);

                    let calcPerCent = (this.DA / this.maxDA) * 100;

                    if ( calcPerCent > 0 && calcPerCent <= 19 ) {
                        targetPie.classList.add('is-10');
                    } else if (calcPerCent >= 20 && calcPerCent <= 29) {
                        targetPie.classList.add('is-20');
                    } else if (calcPerCent >= 30 && calcPerCent <= 39) {
                        targetPie.classList.add('is-30');
                    } else if (calcPerCent >= 31 && calcPerCent <= 49) {
                        targetPie.classList.add('is-40');
                    } else if (calcPerCent >= 41 && calcPerCent <= 59) {
                        targetPie.classList.add('is-50');
                    } else if (calcPerCent >= 51 && calcPerCent <= 69) {
                        targetPie.classList.add('is-60');
                    } else if (calcPerCent >= 61 && calcPerCent <= 79) {
                        targetPie.classList.add('is-70');
                    } else if (calcPerCent >= 71 && calcPerCent <= 89) {
                        targetPie.classList.add('is-80');
                    } else if (calcPerCent >= 81 && calcPerCent <= 99) {
                        targetPie.classList.add('is-90');
                    } else if (calcPerCent === 100) {
                        targetPie.classList.add('is-100');
                    }
                }
                EventHub.emit('syncBidPrice', response.data.data.item.nextBid);
            }
        });
    },
    mounted () {
        CommonUtil.doFixed(true);
    },
    unmounted() {
        CommonUtil.doFixed(false);
    },
    methods: {

        scrToggle(e: any): void {
            e.preventDefault();
        },
        close(e: MouseEvent): void {
            e.preventDefault();
            const confirm: any = window.confirm("【重要】入札が完了していません。\n\n"
                + "入札忘れを防ぐためにも、一旦画面上に表示された入札額にてご入札いただくことをおすすめします。\n"
                + "ご入札いただいた後に他の方からの入札があった場合は、メールでご案内させていただきます。");
            if (confirm) {
                this.$store.dispatch('hideModal');
            }
        },
        bidAuctionItem(e: MouseEvent): void {
            const params: any = {
                bid: this.bidPrice,
                itemId: this.router.itemId
            };

            this.$store.dispatch('bidAuctionItem', params).then((response: any) => {
                if (response.data.success) {
                    let eventArray: string[] = [];
                    let eventStr = '';
                    let price = params.bid;
                    let minPrice = this.$store.state.Item.item.bidMinPrice;
                    let currentPrice = this.$store.state.Item.item.currentPrice;
                    let priceDifference = (this.bidPrice - currentPrice);
                    const holdState = response.data.data.item.hold;
                    const beforeBidHoldState = this.$store.state.Item.item.hold;
                    const beforeBidEventValue = (beforeBidHoldState === 1) ? 'event281' : 'event282';

                    let productStr = '';
                    let bidCount = this.$store.state.Item.item.bidCount;

                    eventArray.push('purchase');
                    if (this.router.market === 'jp' || this.router.market === 'other') {
                        CommonUtil.evar('eVar122', 'detail_bid_'+ params.itemId);
                        eventArray.push('event593', 'event607');
                        if (CommonUtil.getCookie('contents17_auction') === 'contents17_complete') {
                            CommonUtil.events('event620');
                        }
                    }
                    this.firedWebAntena();

                    if (holdState === 1) {
                        eventArray.push(beforeBidEventValue);
                        // 勝者
                        if (this.router.market === 'gtld') {
                            eventArray.push('event80', 'event252');
                        } else if (this.router.market === 'jp') {
                            if (this.isDomainLevelLower) {
                                eventArray.push('event51', 'event252', 'event478', 'event520');
                            } else {
                                eventArray.push('event51', 'event252', 'event520');
                            }
                        } else if (this.router.market === 'other') {
                            eventArray.push('event58', 'event252');
                        } else if (this.router.market === 'sell') {
                            eventArray.push('event94', 'event252');
                        }
                    } else {
                        // 敗者
                        if (minPrice < price) {
                            eventArray.push(beforeBidEventValue);
                            // 最低入札価格より大きい
                            if (this.router.market === 'gtld') {
                                eventArray.push('event80', 'event253');
                            } else if (this.router.market === 'jp') {
                                if (this.isDomainLevelLower) {
                                    eventArray.push('event51', 'event253', 'event478');
                                } else {
                                    eventArray.push('event51', 'event253');
                                }
                            } else if (this.router.market === 'other') {
                                eventArray.push('event58', 'event253');
                            } else if (this.router.market === 'sell') {
                                eventArray.push('event94', 'event253');
                            }
                        } else {
                            // デフォルト
                            if (this.router.market === 'gtld') {
                                eventArray.push('event80');
                            } else if (this.router.market === 'jp') {
                                if (this.isDomainLevelLower) {
                                    eventArray.push('event51', 'event478');
                                } else {
                                    eventArray.push('event51');
                                }
                            } else if (this.router.market === 'other') {
                                eventArray.push('event58');
                            } else if (this.router.market === 'sell') {
                                eventArray.push('event94');
                            }
                        }
                    }

                    let referrer = document.referrer;
                    if (this.router.market === 'gtld') {
                        productStr = 'ドメインオークション;.gtldドメインオークション入札;1;' + priceDifference + ';';
                    } else if (this.router.market === 'jp') {
                        productStr = 'ドメインオークション;.jpドメインオークション入札;1;' + priceDifference + ';';
                    } else if (this.router.market === 'other') {
                        eventArray.push('event457');
                        if (location.search.match(/(?:\?|&)auctionLP_20220708/)) {
                            eventArray.push('event454');
                        } else if (location.search.match(/(?:\?|&)otherauc_parkingLP_20221221/)) {
                            eventArray.push('event456');
                        } else if (referrer.match(/mypage/)) {
                            eventArray.push('event455');
                        }
                        productStr = 'ドメインオークション;手動オークション入札;1;' + priceDifference + ';';
                    } else if (this.router.market === 'sell') {
                        productStr = 'ドメインオークション;売買オークション入札;1;' + priceDifference + ';';
                    }

                    eventStr = eventArray.join();
                    // イベント発火 TODO: ABテスト後にproducts不要になる場合はコメントアウトの別々で発火する処理に替える
                    // CommonUtil.events(eventStr);
                    // CommonUtil.products(productStr);
                    CommonUtil.eventsAndProduct(eventStr, productStr);

                    this.$store.dispatch('render', this.router);
                }
            })
        },
        firedWebAntena(): void {
            if (this.router.market === 'jp' || this.router.market === 'other') {
                let cv = 'auction_bid';
                if (this.router.market === 'other') {
                    cv = 'auction_bid_market_3';
                }
                if (typeof(webantenna) === 'function') {
                    _wa.account = 'WAVCed-1';
                    _wa.cv = cv;
                    _wa.parameters['amount'] = (this.bidPrice as any).toString();
                    _wa.parameters['member_id'] = (this.userName as any).toString();
                    webantenna( true );
                    let wait = function( msec: any ) {
                        let s = ( new Date() ).getTime();
                        while ( ( new Date ).getTime() - s < msec );
                    }
                    wait( 100 );
                }
            }
        },
        forkingEvarValueChange(): any {
            let referrer = document.referrer;
            let evarValueChange = '';

            if (location.search.match(/(?:\?|&)auctionLP_20220708/)) {
                evarValueChange = 'auc_LP_bidbutton_onclick_20220727';
            } else if (referrer.match(/watch-list/)) {
                evarValueChange = 'auc_mypage_watchlist_onclick_20220727';
            } else if (referrer.match(/bid-list/)) {
                evarValueChange = 'auc_mypage_bidlist_onclick_20220727';
            }

            return evarValueChange;
        },
        tooltipOn(tooltipContent: string | undefined = undefined): void {
            if (tooltipContent === 'bidUnitInfo') {
                if (!this.isMobile) {
                    if (this.router.market === 'jp') {
                        CommonUtil.events('event573');
                    } else if (this.router.market === 'other') {
                        CommonUtil.events('event574');
                    }
                    this.tooltipActive = true;
                }
            } else {
                this.tooltipActive = true;
            }
        },
        tooltipLeave(tooltipContent: string | undefined = undefined): void {
            if (tooltipContent === 'bidUnitInfo') {
                if (!this.isMobile) {
                    this.tooltipActive = false;
                }
            } else {
                this.tooltipActive = false;
            }
        },
        tooltipTouchStart(tooltipContent: string | undefined = undefined): void {
            if (this.isMobile) {
                this.tooltipActive = !this.tooltipActive;
                if (tooltipContent === 'bidUnitInfo') {
                    if (this.router.market === 'jp') {
                        CommonUtil.events('event573');
                    } else if (this.router.market === 'other') {
                        CommonUtil.events('event574');
                    }
                }
            }
        }
    },
    computed: {
        item(): any {
            return this.$store.state.Item.item;
        },
        trafficTypeAuction(): boolean {
            if (this.item.trafficType === TRAFFIC_TYPE_AUCTION) {
                return true;
            }
            return false;
        },
        bidPrice(): any {
            return this.$store.state.Bid.bidPrice;
        },
        
    }
});

</script>

<template>
<div>
    <aside class="dac-modal-emphasis is-fixed">
        <h3 class="dac-modal-emphasis__heading">入札内容のご確認
            <a href="javascript:void(0)" class="dac-modal-emphasis__close" @click="close($event);onclickEvar('eVar10', router.market + 'auc_detail_bidconfirmation_close_onclick_201904')"></a>
        </h3>
        <div class="dac-modal-emphasis__content">
            <div class="dac-note">
                <div class="dac-modal-emphasis__body">
                    <h2 class="dac-modal-emphasis__lead--xl">{{ item.domainNameMl ? item.domainNameMl : item.domainName }}</h2>
                    <h3 class="dac-modal-emphasis__sub--xl">
                        {{ numberFormat(bidPrice) }}<span class="dac-modal-emphasis__unit">円</span>
                        <span class="dac-tooltip-wrap" v-if="router.market === 'jp' || router.market === 'other'">
                            <a href="javascript: void(0);"
                               class="dac-tooltip-trigger"
                               @mouseover="tooltipOn('bidUnitInfo')"
                               @mouseleave="tooltipLeave('bidUnitInfo')"
                               @touchstart="tooltipTouchStart('bidUnitInfo')" >
                                <i class="dac-ico-tooltip--question"></i>
                            </a>
                            <div :class="['dac-tooltip__content dac-tooltip__content--modal-bid', tooltipActive ? 'is-active' : '']">
                            入札単位は現在&yen;{{ numberFormat(item.bidIncrement) }}で、ご入力額以下で自動調整しています。
                            </div>
                        </span>
                    </h3>
                    <p class="dac-modal-emphasis__text">で入札します。</p>
                </div><!-- /.dac-modal-emphasis__body -->
                <div class="dac-modal__row">
                    <template v-if="router.market === 'other'">
                        <button
                            type="button"
                            class="dac-btn-secondary--max is-pc"
                            @click="bidAuctionItem($event);onclickEvar('eVar10', router.market + (forkingEvarValueChange()))"
                        >入札する</button>
                        <button
                            type="button"
                            class="dac-btn-secondary--lg is-sp"
                            @click="bidAuctionItem($event);onclickEvar('eVar10', router.market + (forkingEvarValueChange()))"
                        >入札する</button>
                    </template>
                    <template v-else>
                        <button
                            type="button"
                            class="dac-btn-secondary--max is-pc"
                            :class="{'is-disabled': roleId !== '2' && router.market === 'sell'}"
                            @click="bidAuctionItem($event);onclickEvar('eVar10', router.market + 'auc_detail_bidconfirmation_completion_onclick_201904')"
                        >入札する</button>
                        <button
                            type="button"
                            class="dac-btn-secondary--lg is-sp"
                            :class="{'is-disabled': roleId !== '2' && router.market === 'sell'}"
                            @click="bidAuctionItem($event);onclickEvar('eVar10', router.market + 'auc_detail_bidconfirmation_completion_onclick_201904')"
                        >入札する</button>
                    </template>
                </div><!-- /.dac-modal__row -->
            </div><!-- /.dac-note -->
            <div class="dac-note-point">
                <div class="dac-note-point__inner">
                    <AgreementSellModule  v-if="router.market === 'sell'" />
                    <ul class="dac-text-indent--sm" v-else>
                        <li class="dac-text-indent__item">・私は<a :href="'/agreement/a_ag/registration.html?btn_id=' + router.market + 'auc_detail_confirmation_agree_registcont_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">お名前.comドメイン登録規約</a>、ならびに<a :href="'/agreement/a_ag/navi_agree/dispute.html?btn_id=' + router.market + 'auc_detail_confirmation_agree_dispute_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">ドメイン名紛争解決方針</a>、<a :href="'/agreement/a_ag/backorder.html?btn_id=' + router.market + 'auc_detail_confirmation_agree_servicecont_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">お名前.comバックオーダーサービス</a>に規約し上記内容を申請します。</li>
                        <li class="dac-text-indent__item">・ドメイン登録料金のキャンセル、返金が行われないことに同意します。</li>
                        <li class="dac-text-indent__item">・<a :href="'https://www.gmo.jp/csr/governance/privacy-policy/?btn_id=' + router.market + 'auc_detail_confirmation_agree_policy_201904'" class="dac-text__link--dark" target="_blank" rel="noopener">個人情報取扱いについて</a>の利用規約に同意し、ドメイン名の申請をします。</li>
                        <template v-if="roleId === '2'">
                            <li class="dac-text-indent__item">・私はGMOグローバルサイン・ホールディングス株式会社（以下、GSHD）が提供する<a href="https://www.gmosign.com/order/pdf/agreement.pdf" class="dac-text__link--dark" target="_blank" rel="noopener">「電子印鑑GMOサイン」の利用約款</a>、<br>
                                ならびに<a href="https://www.gmogshd.com/privacy/" class="dac-text__link--dark" target="_blank" rel="noopener">GSHDのプライバシーポリシー</a>に同意し、メールアドレスがGSHDに提供されることを承諾します。</li>
                            <li class="dac-text-indent__item">※お名前.comでは、電子署名などにご活用いただける「電子印鑑GMOサイン」の無償版アカウントをあわせてご提供しております。<br>
                                当サービスをお申込み後、「電子印鑑GMOサイン」のアカウント情報をメールにてお送りします。<br>
                                詳しくは<a href="https://www.gmosign.com/" class="dac-text__link--dark" target="_blank" rel="noopener">電子印鑑ならGMOサイン</a>をご参照ください。</li>
                        </template>
                    </ul>
                </div><!-- /.dac-modal-emphasis__content -->
            </div><!-- /.dac-note-point -->
        </div>
    </aside><!-- /.dac-modal-emphasis -->
    <div class="dac-modal__overlay is-fixed" @click="close"></div>
</div>
</template>